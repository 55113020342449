<template>
  <ViewList v-if="disptype === 'list'" :data="jackpots">
    <template #title>
      <v-list-item v-for="(item, i) in jackpots" :key="i">
        <template v-if="item['Current Jackpot']">
          <span class="text-jackpot font-weight-bold"> {{ item["Current Jackpot"] }}</span> on
        </template>
        <SmartLink :to="item.uri"> {{ item.Title || item.Event }} </SmartLink>
      </v-list-item>
    </template>
  </ViewList>
  <ViewCard v-else-if="disptype === 'card'" :data="jackpots" :is-jackpots="true" />
  <ViewTable v-else-if="disptype === 'table'" :data="jackpots" :is-jackpots="true" />
</template>

<script setup>
import { JackpotsQuery } from "@/apollo/queries/JackpotsQuery.gql";

const emit = defineEmits(["nodata"]);
const props = defineProps({
  amount: { type: Number, default: 5 },
  orderby: { type: String, default: "popularity" },
  disptype: { type: String, default: "list" },
  reviewOnly: { type: Boolean, default: false },
  preloaded: { type: Array, default: () => null },
  attributes: { type: Array, default: () => null },
  hydratedEmpty: { type: Boolean, default: false },
});
const { reviewOnly, amount, orderby, preloaded } = toRefs(props);
const page = inject("page");
const title = unref(page)?.rels?.Title || unref(page)?.rels?.title;
const contentType = unref(page)?.typename;
const hostname = unref(page)?.hostname;

if (props.hydratedEmpty) emit("nodata");

const variables = ref({
  where: {
    title,
    contentType,
    reviewOnly: reviewOnly.value,
    hostname,
  },
  limit: amount.value,
  order: orderby.value,
  attributes: unref(props.attributes),
});

const jackpots = ref(preloaded.value);
if (!props.hydratedEmpty && !preloaded.value) {
  const { data } = await useAsyncQuery(JackpotsQuery, variables.value);
  jackpots.value = unref(data)?.JackpotsQuery;
  if (jackpots.value?.length === 0) emit("nodata");
}
</script>
